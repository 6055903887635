.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 2rem;
    color: hsl(0, 0%, 21%);
}
.info {
    font-size: 1rem;
    margin-bottom: 2rem;
    color: hsl(0, 0%, 50%);
}
.loading{
    font-size: 1rem;
    margin-bottom: 2rem;
}