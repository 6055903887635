.tableContainer{
    /* overflow: hidden; */
    /* box-shadow: 0 0 10px 0 hsl(0, 0%, 13%); */
    /* border-radius: 10px; */
    /* border: 1px solid hsl(0, 0%, 96%); */
}
.table{
    width:100%;
    height: auto;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 500;
    color: #494949;
    /* margin-top: 20px; */
    background-color: hsl(0, 0%, 99%);
    /* border: 1px solid hsl(0, 0%, 96%); */
    box-shadow: 0 0 10px 1px hsla(0, 0%, 13%, 0.212);
    border-collapse:collapse; /* Добавяне на този стил */
}
.table thead{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    color: #292929;
}
.table th{
    background-color: hsl(120, 28%, 83%);
    /* border: 1px solid hsl(120, 23%, 70%); */
    padding: 8px;
    text-align: center;
}
.table td{
    /* border: 1px solid hsl(0, 0%, 96%); */
    padding: 8px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    word-wrap: break-word;
    /* text-align: left; */
}
.table tr{
    background-color: hsl(0, 0%, 100%);
    height: 45px;
    cursor: pointer;
}
.table tr:hover{
    background-color: hsl(0, 0%, 94%);
}
.table tr:nth-child(even){
    background-color: hsl(0, 0%, 97%);
}
.table tr:nth-child(even):hover{
    background-color: hsl(0, 0%, 94%);
}
.dateCell{
    font-weight: 500;
    /* height: 40px; */
    color: rgb(58, 58, 58);
    padding: auto;
    line-height: 1;
    font-family: "Montserrat", sans-serif;;
    font-size: 1.3rem;
}
.centerMe{
    text-align: center !important;
}
#blueCell{
    background-color: hsla(206, 98%, 55%, 0.39);
    text-align: center;
}
#greenCell{
    background-color: hsla(120, 100%, 68%, 0.267);
    text-align: center;
}
#redCell{
    background-color: hsla(0, 100%, 50%, 0.267);
    text-align: center;
    /* font-weight: 600; */
}
#yellowCell{
    background-color: hsla(51, 100%, 50%, 0.267);
    text-align: center;
    /* font-weight: 600; */
}
#noQuantity{
    background-color: hsla(51, 100%, 50%, 0.267);
    text-align: center;
}
#noQuantity :nth-child(even){
    background-color: hsl(51, 100%, 80%);
    text-align: center;
}
.disabledProduct{
    color: rgb(139, 139, 139);
    /* font-style: italic; */
}
#checkedProduct{
    background-color: hsla(123, 93%, 47%, 0.267);
    /* font-style: italic; */
}
.disabledProduct #noQuantity{
    color: rgb(139, 139, 139);
    background-color: hsla(206, 14%, 65%, 0.267);
    font-size: 1.2em;
    font-style: italic;
}
.iconButton{
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    /* margin-right: 10px; */
}
.dateAndTimeContainer{
    display: flex;
    max-height: 45px;
    /* justify-content: space-between;
    align-items: center; */
    /* margin-top: 10px;
    margin-bottom: 10px; */
}
.date{
    display: block;
    text-align: center;
    font-size: 1em;
    margin-top: -5px;
}
.time{
    display: block;
    text-align: center;
    font-size: 0.9em;
    font-weight: bold;
    margin-top: -5px;
    margin-bottom: -8px;
    padding: 0px;
    color: #2ab67c;
}
.buttonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
