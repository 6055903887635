.checkInfoContainer {
    display: flex;
    max-width: 90%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: hsl(0, 0%, 99%);
    border: 1px solid hsl(0, 0%, 96%);
    box-shadow: 0 0 10px 1px hsla(0, 0%, 13%, 0.212);
    border-radius: 6px;
}

.checkInfoElement {
    min-width: 250px;
    /* max-width: 90%; */
    height: 60px;
    margin: 5px;
    padding: 10px;
    /* border: 1px solid hsl(0, 0%, 89%); */
    background-color: aliceblue;
    border-radius: 6px;
}

.checkInfoEmptyElement {
    width: 300px;
    height: 60px;
    margin: 5px;
    padding: 10px;
    /* border: 1px solid hsl(0, 0%, 89%); */
}

.checkInfoElement #title {
    font-size: 0.8em;
    margin-bottom: 0px;
    color: #646464;
}

.checkInfoElement .value {
    font-size: 1em;
    font-weight: bold;
    color: #525252;
    margin-left: 23px;
}

.checkInfoElement #icon {
    font-size: 1.5em;
    margin-right: 4px;
    color: #45a5ff;
}

#finishStatus{
    color: #27c200;
}
#progressStatus{
    color: #f7b500;
}
#abortStatus{
    color: #ff4848;
}

@media (max-width: 530px) {
    .checkInfoContainer {
        display: flex;
        max-width: 95%;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        gap: 1px;
    }

    .checkInfoElement {
        width: 100%;
        height: 60px;
        margin-bottom: 5px;
    }

    .checkInfoEmptyElement {
        display: none;
    }

}