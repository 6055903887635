  .container {
    display:block;
    text-align: left;
  }

  .pageTitle {
    color: #585858;
    /* display: flex; */
    /* font-size: 7vmin; */
    font-size: 2.8em;
    /* text-transform: uppercase; */
    font-weight: 400;
    margin-bottom: 0.5rem;
}

  .moreInfo{
    margin-top: 0;
    margin-bottom: 0;
    color:#4c8600b0;
    font-size: 1rem;
    font-weight: 600;
    font-style: italic;
  }

  @media (max-width: 530px) {
    .pageTitle {
      font-size: 1.8em;
      margin-top: 10px;
  }
  }

