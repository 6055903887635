.sideBar{
    height:auto;
    background-color: rgb(255, 255, 255);
    
}
.employeeCode{
    color: rgb(107, 107, 107);
    font-size: small;
    margin-left: 27px;
}
@media (max-width:800px) {
    .sideBar{
        height:auto;
        background-color: rgb(255, 255, 255);
        position: absolute !important;
        z-index: 10;
    }
}