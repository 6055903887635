.formContainerClear {
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.formContainer {
    max-width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    padding-top: 40px;
    padding-left: 100px;
    padding-right: 100px;
    line-height: 1.8em;
    background-color: white;
    border-radius: 20px;
    /* Овални краища */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.formContainer label {
    font-size: 1.2em;
    margin-bottom: 5px;
    font-weight: 500;
    color: #494949;
}

.formContainer input {
    width: 100%;
    font-size: 1em;
    padding-left: 20px;
    /* margin-left: 15px; */
    margin-right: 15px;
    margin-bottom: 5px;
    border-radius: 20px;
    border: 1px solid #c9c9c9;
}


.formContainer input[readonly] {
    font-weight: 500;
    padding-left: 20px;
    margin-right: 15px;
    color: #383838;
    background-color: rgb(221, 221, 221);
}

/* .formContainer input[disabled] {
    font-weight: 500;
    margin-left: 15px;
    margin-right: 15px;
    color: #383838;
    background-color: rgb(165, 50, 50) !important;
} */

.formContainer select {
    width: 100%;
    font-size: 1em;
    padding-left: 20px;
    /* margin-left: 15px; */
    margin-right: 15px;
    margin-bottom: 5px;
    border-radius: 20px;
    font-weight: 500;
    color: #494949;
    border: 1px solid #b5d8e6;
}

.formContainerClear input {
    width: 100%;
    font-size: 1em;
    padding-left: 20px;
    /* margin-left: 15px; */
    margin-right: 15px;
    margin-bottom: 5px;
    border-radius: 20px;
    font-weight: 500;
    color: #494949;
    border: 1px solid #c9c9c9;
}

.formContainerClear input[readonly] {
    font-weight: 500;
    margin-left: 15px;
    margin-right: 15px;
    color: #383838;
    background-color: rgb(221, 221, 221);
}

.formContainerClear select {
    width: 100%;
    font-size: 1em;
    padding-left: 20px;
    /* margin-left: 15px; */
    margin-right: 15px;
    margin-bottom: 5px;
    border-radius: 20px;
    font-weight: 500;
    color: #494949;
    border: 1px solid #b5d8e6;
}

.formContainerClear select option{
    font-size: 1em;
    padding: 40px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    border-radius: 20px;
    font-weight: 500;
    color: #2c2c2c;
    border-radius: 10px;
    border: 1px solid #b5d8e6;
}


.formContainerClear label {
    font-size: 1.2em;
    margin-bottom: 5px;
    font-weight: 500;
    color: #494949;
}

.pd10 {
    padding: 10px;
}

#eanInput {
    display: inline;
    /* width: 86%; */
    margin-right: -30px;
}
#eanScannerButton {
    display: inline;
    height: 38px;
    font-size: 1.5em;
    border-radius: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-top: 0px;
    padding-left: 15px;
    padding-right: 20px;
    margin-left: -32px;
    margin-top: -2px;
    /* background-color: #ebebeb;
    border: 1px solid #cfcfcf; */
}

#eanScannerButton:hover {
    background-color: #dcdcdc;
    border-color: #bfbfbf;
}




@media (max-width: 570px) {

    .formContainerClear {
        max-width: 600px;
        margin-top: 20px;
    }
    
    .formContainer {
        max-width: 800px;
        margin-top: 20px;
        padding:    20px;
        /* padding-left: 20px;
        padding-right: 5px; */
        line-height: 1.8em;
        background-color: white;
        border-radius: 20px;
        /* Овални краища */
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    
    }
    
    .formContainer label {
        font-size: 1.2em;
        margin-bottom: 5px;
        font-weight: 500;
        color: #494949;
    }
    
    .formContainer input {
        width: 100%;
        font-size: 1em;
        padding-left: 20px;
        /* margin-left: 15px; */
        margin-right: 15px;
        margin-bottom: 5px;
        border-radius: 20px;
        border: 1px solid #c9c9c9;
    }
    
    .formContainer input[readonly] {
        font-weight: 500;
        padding-left: 20px;
        margin-right: 15px;
        color: #383838;
        background-color: rgb(221, 221, 221);
    }

    .formContainer input[disabled] {
        font-weight: 500;
        margin-left: 15px;
        margin-right: 15px;
        color: #383838;
        background-color: rgb(165, 50, 50) !important;
    }
    
    .formContainer select {
        width: 100%;
        font-size: 1em;
        padding-left: 20px;
        /* margin-left: 15px; */
        margin-right: 15px;
        margin-bottom: 5px;
        border-radius: 20px;
        font-weight: 500;
        color: #494949;
        border: 1px solid #c9c9c9;
    }
    
    .formContainerClear input {
        width: 100%;
        font-size: 1em;
        padding-left: 20px;
        /* margin-left: 15px; */
        margin-right: 15px;
        margin-bottom: 5px;
        border-radius: 20px;
        font-weight: 500;
        color: #494949;
        border: 1px solid #c9c9c9;
    }
    
    .formContainerClear input[readonly] {
        font-weight: 500;
        margin-left: 15px;
        margin-right: 15px;
        color: #383838;
        background-color: rgb(221, 221, 221);
    }

    
    .formContainerClear select {
        width: 100%;
        font-size: 1em;
        padding-left: 20px;
        /* margin-left: 15px; */
        margin-right: 15px;
        margin-bottom: 5px;
        border-radius: 20px;
        font-weight: 500;
        color: #494949;
        border: 1px solid #c9c9c9;
    }
    
    .formContainerClear select option{
        font-size: 1em;
        padding: 40px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 5px;
        border-radius: 20px;
        font-weight: 500;
        color: #2c2c2c;
        border-radius: 10px;
        border: 1px solid #c9c9c9;
    }
    
    
    .formContainerClear label {
        font-size: 1.2em;
        margin-bottom: 5px;
        font-weight: 500;
        color: #494949;
    }
}