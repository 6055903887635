.sidebar {
    background-color: #0b2948;
    color: #8ba1b7;
}

.sidebar:hover {
    background-color: #00458b;
    color: #b6c8d9
}
.sidebar--container {
    text-align: left;
}

/* .sidebar--menu-item {
    background-color: #04325f;
    color: #000000
} */

/* .sidebar-menu {

    menuContent: #082440;
    icon: '#59d0ff',
    hover: s
        backgroundColor: '#00458b',
            color: '#b6c8d9',
    }

    ,
    disabled: {
        color: '#3e5e7e',
    } */