.green-card {
    /* height: 50%; */
    width: 80%;
    text-align: center;
    /* margin-left: 50px; */
    border: none;
    /* margin-right: 70px; */
    margin: auto;
    margin-top: 20px;
    background: rgb(34, 193, 195);
    background: linear-gradient(22deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
}
.create-store-link {
    font-size: 30px;
    color: beige;
    text-decoration: none;
}
.create-store-plus {
    font-size: 50px;
    color: rgb(37, 37, 37);
    text-decoration: none;
}