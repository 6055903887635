.formContainer {
    width: 600px;
    margin-top: 20px;
    padding: 20px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 1.8em;
    background-color: white;
    border-radius: 20px; /* Овални краища */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Сянка */
}
.progressBarContainer{
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
}
.progressInfo {
    font-size: 0.8em;
    font-weight:500;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
    color: hsl(0, 0%, 50%);
}
.dataNeeded{
    /* color: ivory; */
    /* font-weight: bold; */
    /* background-color: hsl(14, 100%, 66%); */
    background-color: hsl(9, 100%, 86%);
    padding: 10px;
    border-radius: 10px;
    border: 1px solid hsl(0, 100%, 68%);
    /* animation: pulse 400ms 6 alternate;
    animation-delay: 1s; */
}

@keyframes pulse {
    0% {
        background-color: hsl(14, 100%, 66%);
    }
    100% {
        background-color: hsl(14, 100%, 72%);
        color: ivory;
        transform: scale(1.05);
    }
}
#icon{
    color: hsl(17, 100%, 44%);
    margin-right: 8px;
}
.vendor{
    display: block;
    font-weight: 500;
    font-size: 1.2em;
    font-style: italic;
    color: hsl(0, 0%, 50%);
    display: inline-block;
}
.productName{
    display: block;
    margin-top: -5px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 1.6em;
    overflow: hidden;
    color: hsl(0, 0%, 17%);
}
/* .ean{
    font-weight: 400;
    font-size: 1em;
    color: hsl(0, 0%, 50%);
    display: inline-block;
}
.volume{
    margin-left: 40px;
    font-weight: 400;
    font-size: 1em;
    color: hsl(0, 0%, 50%);
    display: inline-block;
}
.planogram{
    font-weight: 400;
    margin-right: 40px;
    font-size: 1em;
    color: hsl(0, 0%, 50%);
    white-space:nowrap;
    display: inline-block;
} */

.productInfoElement{
    display: inline-block;
    font-weight: 400;
    margin-right: 40px;
    font-size: 1em;
    color: hsl(0, 0%, 50%);
}
.expireElement{
    display: block;
    margin-top: 10px;
    font-weight: 500;
    font-style: italic;
    color: hsl(0, 0%, 29%);
    
}
.expireElement h3{
    display: inline;
    margin-left: 5px;
    font-weight: 600;
    font-style: normal;
    /* color: hsl(0, 0%, 14%); */
    color: hsl(17, 100%, 56%);
    
}
#noQuantity{
    font-size: 1.4em;
    font-weight: 500;
    color: hsl(17, 100%, 56%);
}
.formButtonsContainer{
    display: flex;
    justify-content:center;
    margin: auto;
    margin-top: 30px;
}
.checkBox {
    width: 15px;
    height: 15px;
    margin-right: 20px;
}
.checkBoxContainer{
    background-color: white;
    font-size: large;
    font-weight: 500;
    padding: 10px;
    padding-top: 4px;
    width: 100%;
    height: 40px;
    margin-bottom: 5px;
    border-radius: 20px;
}
.checkBoxContainer:hover{
    background-color: #edfaff;;
    border: 1px #b5d8e6;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: grab;
}
.checkedCheckBoxContainer{
    background-color: #acdcee;
    border: 1px #8dd9f5;
    font-size: large;
    font-weight: 500;
    padding: 10px;
    padding-top: 4px;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}
.checkedCheckBoxContainer:hover{
    background-color: #8dd9f5;
    border: 1px #6dbbd8;
    /* padding: 10px; */
    border-radius: 20px;
}

input:read-only{
    font-weight: 500;
    color: #383838;
    background-color: rgb(209, 209, 209);
}
select{
    width: 100%;
    font-size: 0.8em;
}
.alertContainer {
    margin-bottom: 5px;
}

@media (max-width: 530px) {
    .formContainer {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .planogram{
        display: block;
        margin-left: 0px;
    }
  }