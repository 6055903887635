.pageSubtitle {
    font-size: 2rem;
    font-weight: 400;
    color:hsl(0, 0%, 33%);
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

@media (max-width: 530px) {
    .pageSubtitle {
        font-size: 1.5rem;
    }
}