.container {
    margin: auto;
    margin-left: 20px;
    padding-bottom: 60px;
    margin-bottom: 80px;
}

.innerContainer {
    /* position: relative;
    margin-top: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25); */

    display: flex;
    /* Използвайте flexbox за позициониране на съдържанието в центъра */
    align-items: center;
    /* Центрирайте по вертикалата */
    justify-content: center;
    /* Центрирайте по хоризонталата */
    height: 100vh;
}

@media (max-width:800px) {
    .container {
        margin: auto;
        margin-left: 0px;
    }
}