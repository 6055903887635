.mainContainer {
    display: flex;
    align-items: center;
    margin-right: 25px;
  }
  
  .avatar {
    width: 40px; /* Adjust the width of the avatar as needed */
    height: 40px; /* Adjust the height of the avatar as needed */
    border-radius: 50%;
    margin-right: 10px; /* Adjust margin as needed */
    margin-left: 25px;
    margin-top: -10px;
  }
  
  .nameContainer {
    display: flex;
    flex-direction: column;
    /* margin-top: 10px; */
  }
  
  .name {
    font-size: 1.1em; /* Adjust font size as needed */
    font-weight: bold;
    margin-bottom: 1px; /* Adjust margin as needed */
    color:darkslategrey
  }
  
  .email {
    font-size: 0.9em; /* Adjust font size as needed */
    color:rgb(120, 131, 131)
    /* Additional styles for email if required */
  }

  .menu{
    background-color: rgba(68, 68, 68, 0.459);

  }
  .menuIcon{
    margin-right: 10px;
    cursor: pointer;
    color: darkslategrey;
  }
  .menuItem{
    margin-left: 5px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 1.1em;
    text-decoration: none;
    cursor: pointer;
    color: darkslategrey;
  }

  @media (max-width:800px) {
    .mainContainer {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
    .avatar {
      width: 35px; /* Adjust the width of the avatar as needed */
      height: 35px; /* Adjust the height of the avatar as needed */
      border-radius: 50%;
      margin-right: 5px; /* Adjust margin as needed */
      margin-left: 10px;
      margin-top: -10px;
    }
}