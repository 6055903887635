.userInfoContainer{
    display: flex;
    /* max-width: 90%; */
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    /* align-content: stretch; */
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: hsl(0, 0%, 99%);
    border: 1px solid hsl(0, 0%, 96%);
    box-shadow: 0 0 10px 1px hsla(0, 0%, 13%, 0.212);
    border-radius: 6px;
}
.userInfoDataContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    float: left;
    width:100%;
    margin-top: 10px;
}

.employeeInfoDataContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
}
.userInfoDataElements{
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: start;
}
.userInfoName{
    /* width: 100%; */
    display: block;
    width: 100%;
    font-size: 2.5em;
    font-weight: 300;
    color: #525252;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
}

.employeeInfoStoreName{
    /* width: 100%; */
    display: block;
    width: 100%;
    font-size: 2em;
    font-weight: 300;
    color: #525252;
    margin-top: 0px;
    margin-bottom: -20px;
    margin-left: 20px;
}

.employeeInfoStoreAddress{
    /* width: 100%; */
    display: block;
    width: 100%;
    font-size: 0.8em;
    font-weight: 600;
    color: #32a542;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 22px;

}

.userInfoAvatar{
    display: block;
    border-radius: 20px;
    margin: 20px;
    margin-top: 0px;
    width: 330px;
    height: fit-content;
    padding: 10px;
    border: 2px solid hsl(0, 0%, 75%);
}

.userInfoAvatar img{
    width: 280px;
}

.userInfoElement {
    width: 250px;
    height: 55px;
    margin: 5px;
    padding: 10px;
    padding-top: 6px;
    /* min-height: 40px; */
    /* border: 1px solid hsl(0, 0%, 89%); */
    background-color: rgb(254, 255, 234);
    border: 1px solid rgb(233, 235, 209);
    border-radius: 4px;
}

.userInfoElement #title {
    font-size: 0.8em;
    margin-bottom: 0px;
    color: #646464;
}

.userInfoElement .value {
    font-size: 1em;
    font-weight: bold;
    color: #525252;
    margin-left: 23px;
}

.userInfoElement #icon {
    font-size: 1.5em;
    margin-right: 4px;
    color: #45a5ff;
}
.buttonsContainer{
    margin-top: 10px;
    margin-left: 0px;
    display: flex;
    /* align-self: flex-end; */
    flex-wrap: wrap;
    
}
.cardFooter {
    /* border-top: 1px solid rgb(221, 221, 221); */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 20px;
    width: 100%;
    /* position: absolute; */
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: white;
    /* bottom: 0; */
    /* height: 50px; */
}

.actionButton{
    background-color: #ffffff;
    border: 1px solid #dddddd;
    text-decoration: none;
    text-wrap: nowrap;
    font-weight: 500;
    color: rgb(88, 88, 88);
    border-radius: 10px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 20px;
    margin-left: 10px;

}
.mobileActionButton{
    background-color: #ffffff;
    border: 1px solid #dddddd;
    text-decoration: none;
    text-wrap: nowrap;
    font-weight: 500;
    color: rgb(0, 136, 177);
    border-radius: 10px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;

}
.actionButton:hover{
    background-color: #f5f5f5;
    border: 1px solid #cecece;
}
.mobileActionButton:hover{
    background-color: #f5f5f5;
    border: 1px solid #cecece;
}
.actionButton i{
    margin-right: 5px;
    margin-left: 0px;
    color: rgb(61, 61, 61);

}

@media (max-width: 570px) {
    .userInfoContainer{
        max-width: 100%;
        flex-direction: column;
        
        align-items: center;
        margin-bottom: 0px;
    }
    .userInfoDataContainer {
        width: 100%;
        flex-wrap: wrap;
    }
    .userInfoDataElements{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .userInfoElement {
        width: 90%;
        margin-left: 0px;
        height: 60px;
    }
    .userInfoName{
        margin: auto;
    }
    .employeeInfoStoreAddress{
        /* width: 100%; */
        width: 90%;
        margin-left: 0px;
        text-wrap: wrap;
        font-size: 0.9em;
    
    }
    .employeeInfoDataContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        padding: 0%;
        margin-top: 10px;
    }
  }