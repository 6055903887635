.container {
    display: flex;
    flex-direction: column;
  }
  
  .appName {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin: 0; /* Нулиране на отстъпите (margins) */
    padding: 0; /* Нулиране на падингите (paddings) */
  }
  
  .appVersion {
    font-size: 12px;
    color: rgb(3, 165, 38);
    margin: 0; /* Нулиране на отстъпите (margins) */
    margin-top: -8px;
    padding: 0; /* Нулиране на падингите (paddings) */
  }