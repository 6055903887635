body {
  margin: 0;
  font-family: "Roboto Flex", sans-serif !important;
  background-color: whitesmoke;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  width: 100%;
  text-align: left;
  background-color: whitesmoke;
}

.popover {
  background: #555555 !important;
  color: rgb(235, 235, 235) !important;
  border: none !important;
  border-radius: 8px !important;
  /* padding-left: 20px !important;
  padding-right: 20px !important; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3) !important;
  z-index: 1000000 !important;
}

.popover-header {
  background: #686868 !important;
  /* color: rgb(253, 253, 253) !important; */
  font-weight: 300 !important;
  text-align: center !important;
  border-bottom: none !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-bottom: 1px solid #444444 !important; 
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.popover-body {
  color: rgb(235, 235, 235) !important;
  font-weight: bold !important;
  padding: 10px !important;
  text-align: center !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* 
.montserrat-<uniquifier> {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */