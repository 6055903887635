.icon{
    width: 1rem;
    height: 1rem;
}
.badge{
    margin-right: 15px;
}

@media (max-width:800px) {
    .badge{
        margin-right: 10px;
    }
}