.rating-6 i {
    color: rgb(3, 184, 3);
}
.rating-5 i {
    color: rgb(156, 189, 12);
}
.rating-4 i {
    color: rgb(255, 255, 0);
}
.rating-3 i {
    color: rgb(255, 165, 0);
}
.rating-2 i {
    color: rgb(255, 0, 0);
}
.rating-1 i {
    color: rgb(139, 0, 0);
}

.store-name {
    font-size: 1.5rem;
    font-weight: 500;
}

.install span {
    font-size: 12px;
}

.col-md-4 {
    margin-top: 27px;
}

.img-mr {
    margin-right: 10px;
}