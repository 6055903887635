.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: rgb(199, 199, 199);
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.copyRight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  font-size: 0.8em;
  font-weight: 500;
  /* color: rgb(146, 145, 145); */
  /* border: 1px solid #3e3e3e; */
}

.icons {
  display: flex;
  justify-content: right;
  align-items: right;
  margin-right: 0px;
  width: 33%;
  /* border: 1px solid #3e3e3e; */
}

.centerLogo  {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  font-size: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    /* color: rgb(221, 221, 221); */
    margin: 0; /* Нулиране на отстъпите (margins) */
    padding: 0; /* Нулиране на отстъпите (paddings) */
    /* border: 1px solid #3e3e3e; */
}

.icon{
  font-size: 30px;
  color: rgb(199, 199, 199);
  margin-right: 30px;
}

#linkedin:hover{
  color: #0077b5;
}

#github:hover{
  color: #333;
}

#facebook:hover{
  color: #3b5998;
}

#gmail:hover{
  color: #d14836;
}

@media (max-width: 730px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
  }
  .copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.8em;
    font-weight: 500;
    color: rgb(146, 145, 145);
    margin-bottom: 15px;
    /* border: 1px solid #3e3e3e; */
  }

  .icons {
    display: flex;
    /* flex-direction: row; */
    justify-content:space-around;
    gap: 20px;
    /* align-items: center; */
    /* margin-right: 0px; */
    width: 100%;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #3e3e3e; */
  }
  .centerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 30px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 15px;
      /* border: 1px solid #3e3e3e; */
  }
  .icon {
    font-size: 25px;
    color: rgb(146, 145, 145);
    /* margin: auto; */
  }
}