#messageBox {
    /* max-height: auto; */
    opacity: 1;
    animation: fadeOut 1700ms forwards;
    overflow: hidden;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        /* overflow: hidden; */
    }
}