.scannerBody{
    width: 100%;
    height: 80%;
    padding-bottom: -40px;
}

.scannerBody video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.redLine{
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: rgb(255, 0, 0);
    z-index: 1000;
}