.navBar{
    /* height:auto; */
    background-color: rgb(198, 255, 193);
}
.leftContainer{
    display: flex;
}
.sidebarToggler{
    margin-right: -30px;
    margin-left: -10px;
    padding-right: 0px;
}