.popoverListButton {
    background-color: hsl(40, 6%, 91%);
    border: 1px solid hsl(0, 0%, 87%);
    border-radius: 3px;
    /* padding: 5px; */
    margin-top: -20px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    color: #686868;
    transition: 0.3s;
}

.popoverListButton i{
    margin-right: 8px;
    font-size: 0.7rem;
}

.popoverListButton:hover {
    background-color: hsl(0, 0%, 95%);
    color: #3d3d3d;
}