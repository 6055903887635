.card{
    margin: auto;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Разпределение на колоните */
    gap: 10px;
}
.buttonsGroup{
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    margin-top: 10px;
}
.infoP{
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 2px;
}
.businessThumb{
    margin-left: auto;
    padding-right: 0px;
    width: 300px;
}
@media  (min-width: 551px) and (max-width: 1200px)  {
    .card {
        width: auto;
      grid-template-columns: 2fr; /* Промяна на разпределението на колоните за по-малки екрани */
    }
    .businessThumb{
        /* margin-left: auto;
        padding-right: 0px; */
        width: auto;
        grid-column: 2 span;
    }
    .buttonsGroup{
        margin-bottom: 10px;
    }
  }

@media (max-width: 550px) {
    .card {
        width: auto;
      grid-template-columns: 1fr; /* Промяна на разпределението на колоните за по-малки екрани */
    }
    .businessThumb{
        /* margin-left: auto;
        padding-right: 0px; */
        width: auto;
    }
    .buttonsGroup{
        margin-bottom: 10px;
    }
  }