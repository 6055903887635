.dateAndTimeContainer{
    display: flex;
    max-height: 45px;
    /* justify-content: space-between;
    align-items: center; */
    /* margin-top: 10px;
    margin-bottom: 10px; */
}
.date{
    display: block;
    text-align: center;
    font-size: 1em;
    margin-top: -5px;
}
.time{
    display: block;
    text-align: center;
    font-size: 0.9em;
    font-weight: bold;
    margin-top: -5px;
    margin-bottom: -8px;
    padding: 0px;
    color: #2ab67c;
}
